// shop
// ––––––––––––––––––––––––––––––––––––––––––––––––––



.catalog__group-list{

  margin-top: 2rem;
  line-height: 1.2;


  a{
    border: none;

    &:hover{
      color: $base-link-hover-color;
      border: none;
    }
  }
  
  .catalog__group-list__item{
    overflow: hidden;
    position: relative;
    //height: 100%;
    margin-bottom: 1rem;
    background: $color-gray__lighter;
    border-radius: $border-radius-base;
    padding: 2rem;

    &.first{
      span.bg{
        display: none;
        position: absolute;

        background: transparent url(crane.png) left bottom no-repeat;

        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }



    .ttl{
      position: relative;
      font-size: $base-font-size-big;


      i{
        position: relative;
        display: block;
        width: 5rem;
        height: 5rem;
        margin-bottom: 1rem;
      }


    }

    a{
      path{
        @include anim();
      }
      &:hover{
        path{
          fill: $color-red;
        }
      }
    }

    .catalog__group-list__item__subgroup{
      margin-top: 4rem;
      padding-top: 1rem;
      border-top: .1rem solid $color-gray__light;

      ul{
        @include list-unstyled();

        li{
          margin-top: 1.5rem;
        }
      }
    }
  }

  @media (min-width: $screen-desktop) {
    .catalog__group-list__item{
      padding: 2rem 2rem 3rem 2rem;
      margin-bottom: 2rem;
      height: 17rem;

      @include anim();

      &.first{
        height: 36rem;

        /*padding: 18rem 2rem 3rem 10rem;
        span.bg{
          display: block;

        }
        .ttl{
          i{
            display: none;
          }
        }*/
      }

      .ttl{
        margin-right: 5rem;
        @include anim();


      }

      &:hover{
        @include scale(1.05);

        .ttl, .catalog__group-list__item__subgroup{
          //@include scale(0.95)
        }
      }
    }
    .catalog__group-list__item__subgroup{
      @include anim();
      ul{
        margin-right: 5rem;
      }
    }
  }

}


.service-list{
  margin: 2rem 0 0 0;
  ul{
    @include list-inline(2rem)
  }
}

.catalog__group-more{
  padding: 3rem;
  padding-bottom: 2rem;
  background: $color-gray__lighter;
  border-radius: $border-radius-base;

  margin-top: 2rem;

  li{
    margin-bottom: 3rem;
  }

  @media (min-width: $screen-tablet) {
    margin-top: 0;
  }
}

.contacts__fast{
  padding: 2rem 3rem 3rem 3rem;
  background: $color-gray__lighter;
  border-radius: $border-radius-base;
  margin-top: 2rem;

  i{
    display: block;
    margin-bottom: 0.5rem;

    @include anim();
  }

  .phone{
    font-size: 2rem;
    white-space: nowrap;
    margin: 1rem 0;
  }

  .go{
    margin-top: 2rem;
  }

  a{
    border: none;

    &:hover{
      color: $base-link-hover-color;
      border: none;

      i{
        path{
          fill: $base-link-hover-color;
        }
      }
    }
  }

  @media (min-width: $screen-tablet) {
    margin-top: 2rem;
  }
}

table.floatThead-table {
  border-top: none;
  border-bottom: none;
  background: $color-initial;
  background: rgba($color-initial, .95);
  /*position: absolute !important;
  top: 34px;
  right: 0;
  bottom: 0;
  left: 0;*/
  //margin-top: $header-height !important;
  //z-index: 10 !important;
}

.sticky__header{
  position: relative;
  z-index: 10;
}


.catalog-code{
  .catalog-code__block{
    margin: 0 0 4rem;

    &__num
    {
      display: block;
      text-align: center;
      font-size: $base-font-size-small;
      color: $color-gray;
      position: relative;
      height: 2.4rem;


    }
    &__name{
      font-size: $base-font-size-small;
      text-transform: uppercase;
      width: 5rem;
      display: block;
      text-align: center;
      padding: 0.3rem 0;
      border: 0.1rem solid $color-quinary;
      position: relative;

      @include anim();

      cursor: help;
      &:hover, &.hover{
        border: 0.1rem solid $color-primary;
        background: $color-primary;
        color: $color-initial;
      }
    }
    &__sep{
      padding: 0.3rem 0;
      color: $color-gray;
    }

    ul{
      @include list-inline(0.2rem);
      list-style-position: initial;
    }
    ol{
      font-size: $base-font-size-small;
      //@include list-unstyled();
      list-style: decimal;

      margin-top: 2rem;

      li{
        margin-bottom: 0.5rem;
      }

      .catalog-code__block__name{
        display: inline-block !important;


      }

      .catalog-code__block__summary{
        padding-left: 0.7rem;
        &:before{
          content: '\2014';
        }
      }
    }



  }
}

.fast_go{
  color: $color-gray;
}