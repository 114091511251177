// Base
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// Set box-sizing globally to handle padding and border widths
*,
*:after,
*:before {
  box-sizing: inherit;
}

@include selection($base-selection-bg, $base-selection-color, $base-selection-opacity);

// The base font-size is set at 62.5% for having the convenience
// of sizing rems in a way that is similar to using px: 1.6rem = 16px
html {
  box-sizing: border-box;
  font-size: 62.5%;
}

// Default body styles
body {
  color: $base-text-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  letter-spacing: $base-letter-spacing;
  line-height: $base-line-height;
  //background: $color-quaternary;
}


