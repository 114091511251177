// Img
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.img-circle {
  border-radius: 50%; // set radius in percents
}
.img-rounded {
  border-radius: $border-radius-base;
}

.img-responsive {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;

}