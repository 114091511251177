// Form
// ––––––––––––––––––––––––––––––––––––––––––––––––––

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
  appearance: none;
  background-color: $color-initial;
  border: 0.1rem solid lighten($color-gray, 30%);
  border-radius: $button-border-radius;
  box-shadow: none;
  box-sizing: inherit;
  height: $button-height;
  padding: 0.6rem 1.3rem;
  width: 100%;

  @include anim();

  @include placeholder(lighten($color-gray, 10%));

  &:focus {
    border-color: $color-gray;
    outline: 0;
  }
}

select {

  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="48"><path d="M19.1,5.4c0-0.1,0-0.2-0.1-0.2l-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1l-3.5,3.5l-3.5-3.5 C11.1,4.7,11,4.6,11,4.6c-0.1,0-0.2,0-0.2,0.1l-0.4,0.4c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.2,0.1,0.2l4.2,4.2c0.1,0.1,0.1,0.1,0.2,0.1 c0.1,0,0.2,0,0.2-0.1L19,5.6C19.1,5.5,19.1,5.5,19.1,5.4z" fill="#808795"/></svg>') center right no-repeat;


  padding-right: $button-height;
  //background-color: $color-initial;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;

  font-size: $base-font-size;
  font-family: $base-font-family;
  &:focus {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="48"><path d="M19.1,5.4c0-0.1,0-0.2-0.1-0.2l-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0.1l-3.5,3.5l-3.5-3.5 C11.1,4.7,11,4.6,11,4.6c-0.1,0-0.2,0-0.2,0.1l-0.4,0.4c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.2,0.1,0.2l4.2,4.2c0.1,0.1,0.1,0.1,0.2,0.1 c0.1,0,0.2,0,0.2-0.1L19,5.6C19.1,5.5,19.1,5.5,19.1,5.4z" fill="#00487c"/></svg>');
  }
}

textarea {
  min-height: 6.5rem;
}

label,
legend {
  display: block;
  font-size: $base-font-size;
  font-weight: $base-header-font-weight;
  font-family: $base-header-font-family;
  color: $base-header-color;
  margin-bottom: 0.5rem;
}

fieldset {
  border-width: 0;
  padding: 0;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
}

.label-inline {
  display: inline-block;
  font-weight: normal;
  margin-left: 0.5rem;
}
