// Burger
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.header__hamburger{
  float: left;
  position: relative;
  height: $header-height;
  width: 5rem;
  cursor: pointer;
  z-index: 10;

  /*@media (min-width: $screen-tablet) {
    margin-left: 1.4rem;
  }
  @media (min-width: $screen-desktop) {
    display: none;
  }*/
  @media (min-width: 700px) {
    display: none;
  }

}


.burger{
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: 1rem;
  top: 3.1rem;
  width: 2.4rem;
  //line-height: 3.2rem;
  //height: 3.2rem;
  display: inline-block;
  background: transparent !important;
  padding: 0;
  border-radius: 0 !important;
  border: none;

  //position: absolute;

  .lines {
    position: relative;
    display: inline-block;
    width: 2.4rem;
    height: .3rem;
    top: -.3rem;
    //margin-top: -.4rem;
    background: $base-link-color;

    @include anim();

    &:before, &:after {
      position: absolute;
      display: inline-block;
      left: 0;
      content: '';
      width: 2.4rem;
      height: .3rem;
      background: $base-link-color;
      @include anim(.3s);
    }

    &:before {
      top: .8rem;
    }

    &:after {
      top: -.8rem;
    }

  }

  /*&:hover{
    .lines{

      //background: $base-link-hover-color;

      &:before, &:after {
        //background: $base-link-hover-color;
      }

      &:before {
        top: .8rem;
      }

      &:after {
        top: -.8rem;
      }
    }
  }

  &.close .lines {
    @include anim(.5s);
    background: transparent;

    &:before, &:after {
      @include anim(.3s);
      top: -.1rem;
      //background: $color-primary;
    }

    &:before {
      @include rotate(45deg);
    }

    &:after {
      @include rotate(-45deg);
    }
  }

  &.close{
    @include anim(.3s);
  }
  &.close:hover{
    @include rotate(180deg);
  }

  &.invert{
    .lines {
      background: $color-initial;

      @include anim();

      &:before, &:after {
        background: $color-initial;
      }


    }
  }*/

}


.header__nav__open{
  .burger{
    @include anim(.3s);


    .lines {
      @include anim(.5s);
      background: transparent;

      &:before, &:after {
        @include anim(.3s);
        top: -.1rem;
        //background: $color-primary;
      }

      &:before {
        @include rotate(45deg);
      }

      &:after {
        @include rotate(-45deg);
      }
    }

    &:hover{
      @include rotate(180deg);
    }
  }
}