// accordion
// ––––––––––––––––––––––––––––––––––––––––––––––––––




.accordion{
  margin: 0 0 2.8rem 0;
  //

  .accordion-item{
    position: relative;
    //border: 0.1rem solid $color-quinary;
    border-bottom: none;


    &:first-child{
      //@include border-top-radius($border-radius-base*2);
    }

    &:last-child{
      //border-bottom: 0.1rem solid $color-quinary;
      //@include border-bottom-radius($border-radius-base*2);
    }

    .accordion-title{
      @extend h4;
      font-weight: normal;
      font-family: $base-font-family;
      border-bottom: 0.1rem solid $color-quinary;
      padding: 2rem 0;
      margin: 0;
      color: $color-primary;
      cursor: pointer;
      position: relative;
      @include anim();


      &:after{
        content: '\203A';
        position: absolute;
        right: 2.4rem;
        top: 1.3rem;
        font-size: 3rem;
        z-index: 10;
        display: block;
        color: rgba($color-gray, 0.7);
        @include rotate(90deg);
        @include anim();
      }

      &:hover{
        color: $base-link-hover-color;
        //background: $color-tertiary;

        &:after{
          color: $base-link-hover-color;
        }
      }


    }

    &:last-child{
      .accordion-title{
        border: none;
      }
    }

    .accordion-body{
      display: none;
      //border-top: 0.1rem solid $color-quinary;
      padding: 2rem 0 3rem 0;
    }

    &.active{
      .accordion-title{
        color: $base-header-color;

        &:after{
          @include rotate(-90deg);
          //content: '\00d7';
          color: $base-header-color;
          right: 2.7rem;
        }

        &:hover{
          color: $base-link-hover-color;
          //background: $color-tertiary;

          &:after{
            color: $base-link-hover-color;
          }
        }
      }

    }

  }
}
