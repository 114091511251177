// article
// ––––––––––––––––––––––––––––––––––––––––––––––––––


.article-list{

  article{

    margin-bottom: 4rem;


    img{
      &.main{
        display: block !important;
      }
      &.hover{
        display: none !important;
      }
    }



    &:hover{
      img{
        &.main{
          display: none !important;
        }
        &.hover{
          display: block !important;
        }
      }
    }


    .title{
      //@extend h4;
      font-weight: normal;
      margin-bottom: 0.5rem;
      margin-top: 0;

      img{
        margin-bottom: 1rem;
        @include anim();
      }

      a{
        &:hover{
          img{
            opacity: .8;
          }
        }
      }

      &.title-dark{
        a{
          color: $base-text-color;
          border-bottom: none;

          &:hover{
            color: $color-primary;
            border-bottom: none;
          }
        }
      }

    }

    &.article-list-first{
      .title{
        margin-top: 0;
      }
    }

    .summary{
      //margin-top: 0.7rem;
    }



  }

  @media (min-width: $screen-tablet) {

  }
}


.catalog-list{
  .catalog-list__item{
    margin-bottom: 3rem;
  }
}


.sert-list{
  &__item{

    a{
      border-bottom: none;

      img{
        @include anim();
      }

      &:hover{
        img{
          @include scale(1.05);
        }
      }
    }
    margin-bottom: 8rem;

    &__name{
      margin-top: 1rem;
      color: $color-gray;
      font-size: $base-font-size-small;
    }
  }
}