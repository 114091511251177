// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff !default;
$color-primary: #00487c !default;
$color-secondary: #000 !default;
$color-tertiary: #f7f9fa !default;
$color-quaternary: #f5f7f9 !default;
$color-quinary: #e1e1e1 !default;


$color-gray: #808795 !default;
$color-red: #db1435 !default;
$color-gray__light: #d1d1d1 !default;
$color-gray__lighter: #f4f4f4 !default;


$color-success: #2fb457;
$color-danger: $color-red;


//
// Base
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$base-selection-bg: $color-primary;
$base-selection-color: $color-initial;
$base-selection-opacity: .85;


$font-family-serif: Georgia, "Times New Roman", Times, serif;

$base-text-color: $color-secondary;

$base-font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$base-font-size: 1.6rem;
$base-font-size-small: 1.4rem;

$base-font-size-big: 1.8rem;
$base-font-size-lead: 2rem;
$base-font-weight: normal;
$base-letter-spacing: normal;
$base-line-height: 1.65;



$line-height-computed: floor(($base-font-size * $base-line-height));
$border-radius-base: 0.4rem;
$padding-base-vertical: 0.6rem;
$padding-base-horizontal: 1.2rem;
$line-height-base: $base-line-height;



// Headers
// ––––––––––––––––––––––––––––––––––––––––––––––––––
$base-header-color: $color-secondary;
//$base-header-font-family: -apple-system, 'Roboto', 'Helvetica', 'Arial', sans-serif;
$base-header-font-family: $base-font-family;
$base-header-font-weight: bold;
$base-header-letter-spacing: normal;
$base-header-line-height: 1.2;

$base-header-h1-mobile: 3.2rem;
$base-header-h2-mobile: 2.2rem;
$base-header-h3-mobile: 2rem;
$base-header-h4-mobile: 1.6rem;
$base-header-h5-mobile: 1.6rem;
$base-header-h6-mobile: 1.6rem;

$base-header-h1: 3.6rem;
$base-header-h2: 2.6rem;
$base-header-h3: 2.4rem;
$base-header-h4: 1.8rem;
$base-header-h5: 1.6rem;
$base-header-h6: 1.6rem;

$bstrong-font-weight: bold;
$iem-style: italic;



// Links
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$base-link-color: $color-primary;
//$base-link-color: $color-blue;
$base-link-hover-color: $color-red;
//$base-link-hover-color: $color-blue;
$base-link-text-decoration: none;
$base-link-text-decoration-hover: none;



// Buttons
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$button-height: 4.2rem;
$button-line-height: 4rem;
$button-padding: 0 3rem;
$button-border-radius: 0;
$button-font-size: $base-font-size;


//== Pagination
$cursor-disabled:                not-allowed;

$pagination-color:                     $color-primary;
$pagination-bg:                        #fff;
$pagination-border:                    #ddd;

$pagination-hover-color:               $color-primary;
$pagination-hover-bg:                  darken($color-tertiary, 10%);
$pagination-hover-border:              darken($color-tertiary, 10%);

$pagination-active-color:              #fff;
$pagination-active-bg:                 $color-primary;
$pagination-active-border:             $color-primary;

$pagination-disabled-color:            $color-gray__light;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 2rem;
$breadcrumb-padding-horizontal: 0;
//** Breadcrumb background color
$breadcrumb-bg: transparent;
//** Breadcrumb text color
$breadcrumb-color: $color-gray__light;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $base-header-color;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: "\2014";


$enable-grid-classes:       true !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           20px !default;

// END of Grid Options


$screen-phone: 0 !default;
$screen-tablet: 576px !default;
$screen-desktop: 768px !default;
$screen-lg-desktop: 992px !default;

//


$header-height: 9rem;