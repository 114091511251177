// Blockquote
// ––––––––––––––––––––––––––––––––––––––––––––––––––

blockquote {
  //border-left: 0.3rem solid $color-quaternary;
  margin-left: 0;
  margin-right: 0;
  padding: 2rem 1.5rem;

  font-family: $font-family-serif;
  font-size: 2rem;
  font-style: italic;

  *:last-child {
    margin-bottom: 0;
  }
}

