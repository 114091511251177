// body
// ––––––––––––––––––––––––––––––––––––––––––––––––––

html, body {
  position: relative;
  min-height: 100%;
  height: 100%;
}

body{
  padding-top: $header-height;
}

// remove
a.linkme{
  color: $base-link-hover-color !important;
}

ul.dash {
  @include list-unstyled();

  li{

    //margin: 0.5rem 0;
    //padding: 0 0 0 2rem;
    padding-left: 1.8rem;
    list-style-type: none;
    behavior: expression( !this.before
            ? this.before = this.innerHTML = '&mdash;&nbsp;' + this.innerHTML : '' );
    text-indent: -2rem;

    &:before {
      content: '\2014\a0';
    }
  }
}

.nowrap{
  white-space: nowrap;
}
