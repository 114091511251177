// Table
// ––––––––––––––––––––––––––––––––––––––––––––––––––

table {
  border-spacing: 0;
  width: 100%;

  tr{
    td,
    th {
      border-bottom: 0.1rem solid $color-quinary;
      padding: 1rem 1.5rem;
      text-align: left;

      line-height: 1.4;

      /*&:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }*/
    }

  }

  &.table-hover{
    tbody{
      tr{
        @include anim();
        &:hover{
          background: $color-gray__lighter;
        }
      }
    }
  }

  &.table-small{
    tr{
      td,
      th {
        padding: 0.5rem 1.5rem;
        text-align: left;

      }

    }
  }
}

