// Spacing
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
dd,
dt
 {
  margin-bottom: 1.5rem;
  //line-height: 1.1;
}

li{
  //margin-bottom: 1.5rem;
}

ol li{
  margin-bottom: 1.5rem;
}

fieldset,
input,
select,
textarea {
  margin-bottom: 1.5rem;
}

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 1.5rem;
}
