// footer
// ––––––––––––––––––––––––––––––––––––––––––––––––––


.footer {

  background: $color-primary;
  padding: 2rem 0 10rem 0;
  color: $color-initial;
  font-size: $base-font-size-small;

  .footer__logo{
    path{
      fill: $color-initial;
    }
  }

  .phone{
    font-size: 2rem;
  }
  .ttl{
    font-size: $base-font-size-big;
    margin-bottom: 0.8rem;
  }

  .item__list{
    @include list-unstyled();
    li{
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }

  hr{
    border-top-color: rgba($color-initial, .3);
    margin: 4rem 0;
  }

  a{
    color: $color-initial;
    border-bottom: 0.1rem solid transparent;

    &:hover {
      color: $color-initial;
      text-decoration: $color-initial;
      border-bottom: 0.1rem solid rgba($color-initial, .5);
    }
  }


  @media (min-width: $screen-tablet) {
    padding: 8rem 0 12rem 0;
  }

}



