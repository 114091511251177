// autocomplete
// ––––––––––––––––––––––––––––––––––––––––––––––––––


.autocomplete-suggestions {
  width: 100%;
  position: absolute;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  background: $color-initial;
  //background: rgba($color-initial, .95);
  border-top: .1rem solid $color-gray__light;
  //margin-top: -0.4rem;
  padding: .4rem 0;
  @include box-shadow(0 6px 12px rgba($color-secondary, 0.1));


  .autocomplete-suggestion{
    cursor: pointer;
    padding: 8px 10px;
    color: $base-text-color;
    font-size: $base-font-size-small;
    position: relative;

    span.price{
      float: right;
    }


    &.autocomplete-selected {
      background: $color-gray__lighter;
      color: $base-text-color;
    }

    strong{
      color: $color-primary;
      text-decoration: none;
      font-weight: normal;
    }
  }

  .autocomplete-group{
    color: $color-gray;
    font-weight: normal;
    font-size: 10.5px;
    padding: 14px 10px 2px 10px;
    //margin-top: 4px;
    border-top: 1px solid #eee;

    strong{
      font-weight: normal;
    }

    &:first-child{
      border-top: none;
      padding: 8px 10px 2px 10px;
    }
  }

  @media (min-width: $screen-tablet) {
    .autocomplete-group, .autocomplete-suggestion{
      padding-left: 200px;

      &:first-child{
        padding-left: 200px;
      }
    }
  }

}



/*
.store-map {

  @media (min-width: $screen-tablet) {

  }
}
*/

//