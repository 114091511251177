//
// header
// --------------------------------------------------


/*#logo{
  display: none;
}*/



.header{
  position: fixed;
  top:0;
  right: 0;
  left: 0;
  z-index: 500;
  height: $header-height;
  border-bottom: .1rem solid $color-gray__lighter;
  background: $color-initial;
  background: rgba($color-initial, .95);
  @include clearfix();

  a, a:hover{
    border: none;
  }

  a.active{
    color: $base-link-hover-color;
  }

  .header__logo{
    margin-bottom: 0;
    float: left;
    padding: 1rem 1rem 0 1rem;
    position: relative;
    z-index: 10;
    a{
      path{
        @include anim();
      }
      &:hover{
        path{
          fill: $color-red;
        }
      }
    }
  }

  .header__nav {


    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: $header-height;
    bottom: 0;
    overflow: scroll;
    background: $color-initial;
    background: rgba($color-initial, .95);
    padding: 2rem 1rem;
    font-size: $base-font-size-big;

    ul{
      @include list-unstyled();
      margin-bottom: $header-height;
      li{
        display: block;
      }
    }

    &.mobile{




    }


  }

  .header__nav_service{
    display: none;
    float: right;
    //padding-right: 6rem;
    //margin-right: 2rem;
    ul{
      @include list-inline();
      margin-bottom: 0;

      /*li.small{
        display: block;
      }*/
    }
  }

  .header__i18n{
    display: none;
    margin-left: 2rem;
    ul{
      @include list-unstyled();
      margin-bottom: 0;
      //margin-top: 1rem;

      li{
        margin-bottom: 1.4rem;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  .header__search{
    //float: right;
    //position: relative;
    padding-right: 1rem;
    //position: absolute;
    //right: 3rem;



    .header__search_icon{
      //position: relative;
      //margin-top: 1rem;

      position: absolute;
      right: 1rem;
      top: 3.5rem;
      z-index: 30;
      a{
        path{
          @include anim();
        }
        &:hover{
          path{
            fill: $color-red;
          }
        }
      }

    }




    .header__search_form{
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      top:0;
      height: $header-height;
      //margin-top: -3.9rem;
      z-index: 20;

      form, input{
        margin-bottom: 0;
      }

      input{
        height: $header-height - .1rem;
        border: none;
        //background: transparent !important;

      }

      &.hover{
        display: block;
      }

    }
  }


  @media (min-width: $screen-tablet) {
    .header__logo {
      z-index: 40;
      padding: 1rem 3rem 0 2rem;
    }

    .header__nav{
      padding: 2rem 2rem  ;
    }



    .header__search{
      padding-right: 3rem;

      .header__search_icon{
        right: 2rem;
      }

      .header__search_form{
        input{
          padding: 0 7rem 0 20rem;
        }
      }
    }

  }

  @media (min-width: 700px) {
    .header__nav{
      display: block;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;


      overflow: auto;
      background: transparent;
      padding: 0 3rem 0 0;

      ul{
        @include list-inline();
        margin-bottom: 0;
      }

      .header__nav__mobile{
        display: none;
      }
    }

  }


  @media (min-width: 890px) {

    .header__nav_service{
      display: block;
    }

    .header__i18n{
      display: block;
    }
  }

  @media (min-width: $screen-desktop) {

    .header__search {
      padding-right: 7rem;
    }
  }


}


.icon__flag
{
  background: url("flags.png") no-repeat 0 9px;
  display: block;
  //vertical-align: baseline;
  position: relative;
  overflow: hidden;
  width: 16px;
  height: 9px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;


  &.flag-RU{
    background-position: 0 -1764px;
  }
  &.flag-US {
    background-position: 0 -2142px;
  }

}


.header__nav__open{
  .header__nav{
    display: block;
  }
}

