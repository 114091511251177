// rama
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.rama{
  //margin-bottom: 1rem !important;

  overflow: hidden;
  position: relative;

  @media (min-width: $screen-tablet) {
    //margin-bottom: 2rem !important;
  }
}

$arrSize: 46px;

.js-rama{
  margin-bottom: 0 !important;
  background-color: $color-primary;
  overflow: hidden;
  //position: relative;

  .js-rama__item{
    position: relative;



    a, a:hover{
      display: block;
      border: none;
      color: $color-initial;
    }

    .js-rama__item__content{
      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;

      .row, .container-fluid{
        height: 100%;
      }
    }
  }

  .js-rama__item__ttl{
    display: block;
    @extend h1;
    color: $color-initial;
    margin-top: 0;
    //width: 70%;
  }
  .js-rama__item__summary{
    //display: block;
    //width: 60%;
    display: none;

  }

  .js-rama__item__go{
    margin-top: 3rem;
    //display: none;
  }

  .slick-arrow{
    display: none ;
    position: absolute;
    width: $arrSize !important;
    height: $arrSize !important;
    //left: 50%;
    top: 50%;
    margin-top: -($arrSize / 2);
    background: none;
    //display: none !important;
    background: $color-initial;
    @include box-shadow(0 .5rem 2rem 0 rgba(0,0,0,0.1));
    border-radius: 100%;
    z-index: 20;

    opacity: 0;


    &:before{
      content: ' ';
      display: block;
      position: relative;
      width: 16px;
      height: 16px;
      border-top: solid 1px $base-header-color;
    }

    &.slick-prev{
      @include anim();
      left: 2rem;
      right: auto;

      @include translate(-14rem, 0);

      &:before{
        border-left: solid 1px $base-header-color;
        margin: 14px 0 0 18px;
        @include rotate(-45deg);
      }
    }

    &.slick-next{
      @include anim();
      right: 2rem;
      left: auto;

      @include translate(14rem, 0);

      &:before{
        border-right: solid 1px $base-header-color;
        margin: 14px 0 0 11px;
        @include rotate(45deg);
      }
    }
  }


  .slick-dots{
    display: block;
    position: absolute;
    bottom: 4px;
    @include list-unstyled();

    li{
      margin: 0;
      button{
        display: block;
        width: 8px;
        height: 8px;
        position: relative;
        top: 12px;
        left: 6px;
        border-radius: 8px;
        background: $color-initial;
        opacity: 0.5;
        padding: 0;
        //content: '';

        &:before{
          display: none;
        }
      }

      &.slick-active{
        button{
          background: $color-initial;
          opacity: 1;
        }
      }
    }

    @media (min-width: $screen-tablet) {
      bottom: 10px;
    }
  }

  &:hover{
    .slick-arrow{
      opacity: 1;
      &.slick-prev,&.slick-next
      {
        @include translate(0, 0);
      }
    }
  }





  @media (min-width: $screen-tablet) {

    .js-rama__item {

      .js-rama__item__content {
        bottom: 10rem;
      }
    }

    .js-rama__item__ttl{
      width: 60%;
    }



    .js-rama__item__go{
      display: block;
    }

    .slick-arrow, .slick-dots{
      display: none !important;
    }
  }

  @media (min-width: $screen-desktop){
    .js-rama__item__summary{
      display: block;
      font-size: 2rem;
      width: 50%;
    }
  }
}

.js-rama__nav{
  position: absolute;
  bottom: 5rem;
  left: 0;
  right: 0;
  display: none;

  .js-rama__nav__container{
    border-top: 0.1rem solid rgba($color-initial, 0.3);
  }


  .js-rama__nav__item{
    padding: 1.5rem 0 0 0;
    color: $color-initial;
    border-top: 0.3rem solid transparent;
    @include anim();


    .name{
      cursor: pointer;
      opacity: .5;
      //font-size: $base-font-size-big;
      @include anim();

      &:hover{
        opacity: 1;
      }
    }

    &.active{
      border-top: 0.3rem solid $color-initial;
      .name{
        opacity: 1;
      }
    }

  }

  @media (min-width: $screen-lg-desktop) {
    display: block;
  }

}

.slick-slide{

  .js-rama__item__content{
    opacity: 0;
    @include translate(0,20rem);
    @include anim(1s);
  }


  &.slick-active{
    .js-rama__item__content{
      opacity: 1;
      @include translate(0,0)
    }
  }

}

