//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  font-size: $base-font-size-small;
  padding: 2rem 0 2rem 0;
  //margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;
  margin-bottom: 0;
  color: $color-gray;

  > li {
    display: inline-block;

    max-width: 20rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    /*&:last-child{
      max-width: auto;
      overflow: visible;
      white-space: nowrap;
      text-overflow: ellipsis;
    }*/

    a{
      color: $color-gray;
      border: none;

      &:hover{
        border: none;
        color: $base-link-hover-color;
      }
    }

    + li:before {
      //font:normal normal normal 14px/1 FontAwesome;
      //content: "\2014"; // Unicode space added since inline-block means non-collapsing white-space
      content: "\002F"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 .5rem 0 0.2rem;
      color: $breadcrumb-color;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }

  @media (min-width: $screen-tablet) {
    padding: 4rem 0 2rem 0;
  }

}
