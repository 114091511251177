// file
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.file{
  display: block;
  padding-left: 5rem;
  padding-top: 0.7rem;
  line-height: 1.2;
  position: relative;
  min-height: 5rem;


  a{
    //display: inline-block;
    //position: relative;
    border: none;

    &:hover{
      color: $base-link-hover-color;
      border: none;
    }
  }


  .icon {
    position: absolute;
    width: 4rem;
    height: 5rem;
    display: block;
    left: 0;
    top: 0;

    i{
      position: absolute;
      left: .2rem;
      top: 2.2rem;
      font-size: 1rem;
      width: 3.1rem;
      text-align: center;
      font-style: normal;
      color: $color-initial;
    }
  }

  .summary{
    display: block;
    margin-top: 0.4rem;
    font-size: 1.2rem;
    color: $color-gray;
  }

  &.file__pdf{
    .icon .file__type{
      fill: $color-red;
    }
  }

  &.file__doc{
    .icon .file__type{
      fill: $color-primary;
    }
  }

  &.file__xls{
    .icon .file__type{
      fill: $color-success;
    }
  }

  &.file__zip{
    .icon .file__type{
      fill: orange;
    }
  }
}
