// tabs
// ––––––––––––––––––––––––––––––––––––––––––––––––––




.tabs{
  margin: 0 0 2.8rem 0;
  //

  ul.tabs-menu{
    //@include list-inline(2rem);
    list-style: none;
    //margin-bottom: 1rem;
    //margin-bottom: 0;
    //font-size: 1.8rem;
    //font-weight: bold;
    border-bottom: .1rem solid transparent;
    margin: 0;

    li{
      display: inline-block;
      //padding-bottom: 1rem;
      //font-size: 1.6rem;
      //padding-bottom: .5rem;
      margin: 0 2rem;
      padding-bottom: 1rem;
      border-bottom: .2rem solid transparent;

      @include anim();

      &:first-child{
        margin-left: 0;
      }

      &:last-child
      {
        margin-right: 0;
      }

      a{
        //padding: 0.5rem 1rem;
        border: none;

        &:hover{
          color: $base-link-hover-color;
          border: none;
        }
      }

      &.active{

        border-bottom: .1rem solid transparent;

        a{

          color: $base-link-hover-color;
          //background: $color-primary;
          //border-radius: $border-radius-base;

          cursor: default;

        }
      }

    }
  }


  .tab {
    //border: 0.1rem solid $color-tertiary;
    position: relative;
    //background-color: #fff;
    //float: left;
    //margin-bottom: 20px;
    //width: auto;

    .tab-content {
      padding: 2rem 0 0 0;
      display: none;

      h1, .h1,
      h2, .h2,
      h3, .h3,
      h4, .h4,
      h5, .h5,
      h6 .h6 {
        margin-top: 0;
      }

      &:first-child{
        display: block;
      }
    }
  }

  @media (min-width: $screen-tablet) {



    ul.tabs-menu{
      //font-size: 1.8rem;
      //@include list-inline(1rem);

      //margin-left: -2.5rem;

      border-bottom: .1rem solid darken($color-tertiary, 5%);

      li{
        font-size: 2rem;
        a{
          //padding: 0.8rem 2rem;
        }
        &.active{
          border-bottom: .2rem solid $base-link-hover-color;
        }

      }
    }
  }



}
