// page
// ––––––––––––––––––––––––––––––––––––––––––––––––––


.page {

  padding: 0 0 6rem 0;

  .nav__submenu{
    color: $color-gray;
    @include list-inline(1rem);
  }

  &.page__main{
    padding-bottom: 14rem;
  }


  @media (min-width: $screen-tablet) {
    padding: 0 0 14rem 0;


  }

  @media (min-width: $screen-desktop) {
    &.page__main{
      padding-bottom: 0;
    }
  }

}


.text-gray, .text-muted {

  color: $color-gray;
}

.main-text__item{

  margin: 2rem 0 2rem 0;

  .ttl{
    font-size: $base-font-size-big;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  .summary{
    margin-top: 1rem;
    color: $color-gray;
    line-height: 1.4;
  }

  .plus{
    color: $color-primary;
    line-height: 1.2;

    .num{
      color: $color-red;
      font-size: 4.8rem;
      padding-right: 1rem;
    }
  }
  @media (min-width: $screen-desktop) {
    .ttl{
      position: relative;
      min-height: 8rem;
    }
  }

  @media (min-width: 1400px) {
    .ttl{
      position: relative;
      min-height: 4rem;
    }
  }

}

.main-text{
  padding-top: 4rem;

  @media (min-width: $screen-tablet) {
    padding-top: 8rem;
  }
}

.ru-map{
  position: relative;
  display: none;

  img{
    @include noselect();
  }

  .ru-map__center{
    position: absolute;
    width: .1rem;
    height: 100%;
    top:0;
    left: 50%;
  }

  .main-text__item{
    position: absolute;
    margin: 0;


    .plus{
      color: $color-initial;

      .num{
        color: $color-initial;
      }
    }

    &.pos-1{
      left: -36rem;
      bottom: 3rem;

    }

    &.pos-2{
      left: 4rem;
      bottom: 3rem;

    }
    &.pos-3{
      left: 20rem;
      bottom: 3rem;

    }

  }

  @media (min-width: $screen-desktop) {
    display: block;

  }
}


.subinfo-item{
  //font-size: $base-font-size-big;
  line-height: 1.4;

  img{
    margin-bottom: 1rem;
  }

  a{
    border: none;

    &:hover{
      color: $base-link-hover-color;
      border: none;
    }
  }
}