// Link
// ––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $base-link-color;
  text-decoration: $base-link-text-decoration;
  outline: none;
  @include anim();
  //position: relative;
  //

  &:focus,
  &:hover {
    color: $base-link-hover-color;
    text-decoration: $base-link-text-decoration-hover;
    //border-bottom: 0.1rem solid rgba($base-link-hover-color, .3);
  }

  &:not(.button){
    border-bottom: 0.1rem solid rgba($base-link-color, .3);

    &:focus,
    &:hover {
      border-bottom: 0.1rem solid rgba($base-link-hover-color, .3);
    }
  }

  /*&:before{
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    height: .1rem;
    background: $base-link-color;
    width: 0.1rem;
    top: calc(100% + 0.1rem);
    opacity: 0;
    @include anim();
  }
  &:focus,
  &:hover {
    &:before
    {
      width: 100%;
      opacity: .5;
      background: $base-link-hover-color;
      !*transform: scale3d(1,1,1);*!
    }

  }*/

}
