//
// preloader
// --------------------------------------------------

$preloaderZIndex: 2030;

#preloader{
  position: fixed;
  z-index: $preloaderZIndex;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $color-initial;

  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;


  visibility: visible;
  opacity: 1;

  .symbol{
    position: fixed;
    z-index: 1000;
    top: 20px;
    left: 20px;

  }

  &.hideme{
    //display: none;
    visibility: hidden;
    opacity: 0;
    @include anim(1.5s);
  }

  @media (min-width: $screen-tablet) {
    .symbol {
      top: 30px;
      left: 40px;
    }
  }

}


