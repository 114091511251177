// Button
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  background-color: $color-primary;
  border: 0.1rem solid $color-primary;
  border-radius: $button-border-radius;
  color: $color-initial;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  height: $button-height;
  line-height: $button-line-height !important;
  padding: $button-padding;
  text-align: center;
  text-decoration: none !important;
  //text-transform: uppercase;
  white-space: nowrap;
  font-size: $button-font-size !important;

  @include anim();

  &:focus,
  &:hover {
    background-color: $color-red;
    border-color: $color-red;
    color: $color-initial !important;
    outline: 0;
  }

  &[disabled], &.disabled {
    cursor: default;
    opacity: 0.5;
    background-color: lighten($color-gray, 30%);
    border-color: lighten($color-gray, 30%);


    &:focus,
    &:hover {
      background-color: lighten($color-gray, 30%);
      border-color: lighten($color-gray, 30%);
    }
  }

  &.button-invert {

    background-color: $color-red;
    color: $color-initial;
    border-color: $color-red;

    &:focus,
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-initial !important;;
      outline: 0;
    }

    &[disabled], &.disabled {
      cursor: default;
      opacity: 0.5;
      background-color: lighten($color-gray, 30%);
      border-color: lighten($color-gray, 30%);


      &:focus,
      &:hover {
        background-color: lighten($color-gray, 30%);
        border-color: lighten($color-gray, 30%);
      }
    }
  }


  &.button-outline {
    background-color: transparent;
    color: $color-primary;
    border-color: rgba($color-primary, 0.3);

    &:focus,
    &:hover {
      background-color: transparent;
      border-color: $color-primary;
      color: $color-primary !important;;
    }

    &[disabled], &.disabled {
      border-color: lighten($color-gray, 20%);
      color: lighten($color-gray, 20%);

      &:focus,
      &:hover {
        border-color: lighten($color-gray, 20%);
        color: lighten($color-gray, 20%) !important;;
      }
    }

    &.button-invert{
      background-color: transparent;
      color: $color-red;
      border-color: rgba($color-red, 0.3);

      &:focus,
      &:hover {
        background-color: transparent;
        border-color: $color-red;
        color: $color-red !important;;
      }

      &[disabled], &.disabled {
        border-color: lighten($color-gray, 20%);
        color: lighten($color-gray, 20%) !important;;

        &:focus,
        &:hover {
          border-color: lighten($color-gray, 20%);
          color: lighten($color-gray, 20%) !important;;
        }
      }
    }

    &.button-white{
      background-color: transparent;
      color: $color-initial;
      border-color: rgba($color-initial, 0.3);

      &:focus,
      &:hover {
        background-color: transparent;
        border-color: $color-initial;
        color: $color-initial !important;
      }

      &[disabled], &.disabled {
        border-color: lighten($color-initial, 20%);
        color: lighten($color-initial, 20%) !important;

        &:focus,
        &:hover {
          border-color: lighten($color-initial, 20%);
          color: lighten($color-initial, 20%) !important;
        }
      }
    }
  }



}
